import React, {Component} from 'react';
import './App.scss';
import Common from "../components/Common/Common";
class App extends Component {
    s3Url = 'https://elder-patten-ferreira-resume.s3-us-west-2.amazonaws.com/';
    
    state = {
        ItemId: 123,
        header: {
            "name": "Elder",
            "metadata": [
                // {
                //     "icon": "far fa-envelope fa-fw mr-2",
                //     "transform": "grow-3",
                //     "class": "mb-2",
                //     "value": "elderpattenferreira@gmail.com"
                // }
            ],
            "socialMedia": [
                // {
                //     "class": "fab fa-linkedin-in fa-fw",
                //     "url": "linkedin.com/in/elder-patten-ferreira",
                //     "label": "linkedin.com/in/elder-patten-ferreira"
                // },
                // {
                //     "class": "fab fa-github-alt fa-fw",
                //     "url": "github.com/elderferreiras"
                // },
                {
                    "class": "fab fa-youtube fa-fw",
                    "url": "youtube.com/channel/UCZnoo50Qofo3uUCrZ_pz1mg",
                    "label": "channel.thenextlevel.dev"
                },
                // {
                //     "class": "fab fa-stack-overflow",
                //     "url": "stackoverflow.com/users/10056846/elder-patten-ferreira",
                //     "label": "stackoverflow.com/elderferreira"
                // }
            ],
            // "title": "Software Developer",
            "title": "",
            "profileImageUrl": "https://elder-patten-ferreira-resume.s3.us-west-2.amazonaws.com/assets/images/elder.jpeg"
        },
        body: {
            careerSummary: ["Hi, I'm Elder and I live in Seattle."]
        }
    };

    componentDidMount() {
        // axios.get('resume').then(res => {
        //     if(res.data) {
        //         this.setState(res.data);
        //     }
        // })
    }

    render() {
        let root = (
            <div id="circle">
                <div className="loader">
                    <div className="loader">
                        <div className="loader">
                            <div className="loader">

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );

        if(this.state.ItemId) {
            root =  <Common state={this.state}/>;
        }

        return root;
    }
}

export default App;
